<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
      <div v-if="errors.length" class="tw-pt-24 tw-text-center">
        <div>
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="(error,index) in errors" :key="index">{{ error }}</li>
          </ul>
        </div>
      </div>
      <div v-if="status" class="tw-pt-24 tw-text-center">
        <v-alert outlined prominent border="left"  type="success">
          <div class="title">{{ this.status }}</div>
        </v-alert>
      </div>

    <div class="tw-pt-24 tw-text-center">
      <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-2">{{ $t('auth.reset_password') }} </h1>
      <p class="tw-text-sm tw-text-gray-n3 tw-mb-0">Please enter a new password.</p>
    </div>
    <form class="tw-w-2/5 tw-mx-auto tw-text-left tw-mt-6" @submit.prevent="onSubmit" method="POST">
      <div>
        <label for="email" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          {{ $t('auth.email') }}<span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2">
          <input v-model="form.email" autofocus id="email" name="email" type="email" autocomplete="email" required class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
        </div>
      </div>

      <div class="tw-mt-6">
        <label for="password" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          Password <span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2 tw-relative tw-rounded-xl tw-border tw-border-gray-n1">
          <input v-model="form.password" id="password" name="password" :type="showPasswordValue ? 'text' : 'password'" autocomplete="current-password" required class="tw-appearance-none tw-bg-transparent tw-block tw-w-full tw-px-4 tw-py-3 tw-border-none tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
          <a class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center" @click.prevent.stop="togglePassword" @mouseleave="hidePassword">
            <eye-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </a>
        </div>
      </div>

      <div class="tw-mt-6">
        <label for="confirm_password" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          {{ $t('auth.confirm_password') }} <span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2 tw-relative tw-rounded-xl tw-border tw-border-gray-n1">
          <input v-model="form.password_confirmation" id="confirm_password" name="confirm_password" :type="showPasswordValue ? 'text' : 'password'" autocomplete="confirm_password" required class="tw-appearance-none tw-bg-transparent tw-block tw-w-full tw-px-4 tw-py-3 tw-border-none tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
          <a class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center" @click.prevent.stop="togglePassword" @mouseleave="hidePassword">
            <eye-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </a>
        </div>
      </div>

      <button type="submit" class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-shadow-sm tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
        {{ $t('auth.reset_password') }}
      </button>
    </form>

  </div>

</template>

<script>
import Form from 'vform'
import i18n from '@/i18n'
import { EyeIcon } from 'vue-feather-icons'

export default {
  middleware: 'guest',
  components: {
    EyeIcon,
  },
  metaInfo () {
    return { title: i18n.t('auth.reset_password') }
  },

  data() {
    return {
      status: '',
      request: null,
      valid: false,
      showPassword: false,
      showPasswordValue: false,
      form: new Form({
        token: '',
        email: '',
        password: '',
        password_confirmation: ''
      }),
      errors: [],
      emailRules: [
            v => !!v || i18n.t('auth.email_required'),
            v => /.+@.+/.test(v) || i18n.t('auth.email_valid'),
      ],
      rules: {
          required: value => !!value || i18n.t('auth.required'),
          min: v => v.length >= 8 || i18n.t('auth.password_min'),
      },
      confirmPasswordRules: [
          (value) => !!value || i18n.t('auth.password_confirm_type'),
          (value) => value === this.form.password || i18n.t('auth.password_mismatch'),
      ],
    }
  },

  created () {
    this.form.email = this.$route.query.email
    this.form.token = this.$route.params.token
  },

  methods: {
   checkForm: function () {
      this.errors = [];

      if (!this.form.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.form.email)) {
        this.errors.push('Valid email required.');
      }

      if (!this.form.password) {
        this.errors.push(i18n.t('auth.required_password'));
      }else{
        if(this.form.password.length < 8){
          this.errors.push(i18n.t('auth.password_min'));
        }
        if (this.form.password !== this.form.password_confirmation) {
          this.errors.push(i18n.t('auth.password_mismatch'));
        }
      }

      if (!this.errors.length) {
        return true;
      }

      return false;
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    togglePassword() {
      this.showPasswordValue = !this.showPasswordValue;
    },
    hidePassword() {
      this.showPasswordValue = false;
    },
    onSubmit () {
      if(this.checkForm()) this.reset();
    },
    async reset () {
      await this.form.get('sanctum/csrf-cookie')
      await this.form.post('/api/password/reset')
            .then(({data})  => {
               this.status = data.status
            })
          .catch(error => {
              this.status = error.data.email
              this.request = error.request.status
              console.log(error)
            })
            .finally(() => {
              this.form.reset();
              this.$router.push({name: 'login'});
            })

    }
  }
}
</script>
